import React, { useState, useRef, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { MessageSquare } from 'lucide-react';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import './PdfViewer.css';

// Configure worker
pdfjs.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.4.168/pdf.worker.min.mjs';

interface PdfViewerProps {
    pdfUrl: string;
}

const PdfViewer: React.FC<PdfViewerProps> = ({ pdfUrl }) => {
    const [numPages, setNumPages] = useState<number | null>(null);
    const [pageScale, setPageScale] = useState(1);
    const [error, setError] = useState<string | null>(null);
    const [showCommentButton, setShowCommentButton] = useState(false);
    const [pageWidth, setPageWidth] = useState<number>(0);

    const containerRef = useRef<HTMLDivElement>(null);
    const pageRefs = useRef<(HTMLDivElement | null)[]>([]);

    useEffect(() => {
        const handleResize = () => {
            if (containerRef.current && pageWidth) {
                const containerWidth = containerRef.current.clientWidth;
                const newScale = containerWidth / pageWidth;
                setPageScale(newScale);
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [pageWidth]);

    const handleDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
        setNumPages(numPages);
        pageRefs.current = new Array(numPages).fill(null);
        setError(null);
    };

    const handleDocumentLoadError = (error: Error) => {
        console.error('Error loading PDF:', error);
        setError("Oops something went wrong loading the PDF...");
    };

    const handleSelection = () => {
        const selection = window.getSelection();
        setShowCommentButton(!!(selection && !selection.isCollapsed));
    };

    const commentWithHighlight = () => {
        const selection = window.getSelection();
        if (selection) {
            const selectedText = selection.toString();
            if (selectedText.trim()) {
                const range = selection.getRangeAt(0);
                const hasImage = Array.from(range.cloneContents().childNodes).some(
                    node => node.nodeName === 'IMG'
                );

                if (hasImage) {
                    console.log('Image selected!');
                } else {
                    console.log('Selected text:', selectedText);
                }
            }
        }
        setShowCommentButton(false);
    };

    const handleScroll = () => {
        if (!containerRef.current) return;
        let closestDistance = Infinity;

        pageRefs.current.forEach((ref, index) => {
            if (ref) {
                const rect = ref.getBoundingClientRect();
                const distance = Math.abs(rect.top + rect.height / 2 - window.innerHeight / 2);
                if (distance < closestDistance) {
                    closestDistance = distance;
                }
            }
        });
    };

    const handlePageRenderSuccess = (page: any) => {
        if (!pageWidth) {
            const viewport = page.getViewport({ scale: 1 });
            setPageWidth(viewport.width);
        }
    };

    return (
        <div className="pdf-viewer-wrapper">
            <div
                ref={containerRef}
                className="pdf-viewer-container"
                onMouseUp={handleSelection}
                onTouchEnd={handleSelection}
                onScroll={handleScroll}
            >
                {error ? (
                    <div className="pdf-error">{error}</div>
                ) : (
                    <Document
                        file={pdfUrl}
                        onLoadSuccess={handleDocumentLoadSuccess}
                        onLoadError={handleDocumentLoadError}
                        loading={
                            <div className="pdf-loading">
                                <div className="loading-spinner"></div>
                                Loading PDF...
                            </div>
                        }
                    >
                        {Array.from(new Array(numPages || 0), (_, index) => (
                            <div
                                key={`page_wrapper_${index + 1}`}
                                ref={el => pageRefs.current[index] = el}
                                className="page-wrapper"
                            >
                                <Page
                                    pageNumber={index + 1}
                                    scale={pageScale}
                                    className="pdf-page"
                                    onRenderSuccess={handlePageRenderSuccess}
                                    loading={
                                        <div className="page-loading">
                                            <div className="loading-spinner"></div>
                                            Loading page {index + 1}...
                                        </div>
                                    }
                                />
                            </div>
                        ))}
                    </Document>
                )}
            </div>
            {showCommentButton && (
                <div className="comment-button-container">
                    <button
                        className="comment-button"
                        onClick={commentWithHighlight}
                    >
                        <MessageSquare className="comment-icon" size={16} />
                        <span className="comment-text">Comment with Highlight</span>
                    </button>
                </div>
            )}
        </div>
    );
};

export default PdfViewer;